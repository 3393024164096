import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import asyncComponent from './AsyncComponent';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AdminRoute from './AdminRoute';
import SSOExpiredSession from '../pages/SSOExpiredSession/SSOExpiredSession'

const AsyncAuth = asyncComponent(() =>
  import('../pages/Auth/Auth')
);
const AsyncCallback = asyncComponent(() =>
  import('../pages/Callback/Callback')
);
const AsyncHome = asyncComponent(() =>
  import('../pages/Home/Home')
);
const AsyncLogin = asyncComponent(() =>
  import('../pages/Login/Login')
);
const AsyncResetPassword = asyncComponent(() =>
  import('../components/ResetPassword/ResetPassword')
);
const AsyncExpiredPassword = asyncComponent(() =>
  import('../components/ExpiredPassword/ExpiredPassword')
);
const AsyncSettings = asyncComponent(() =>
  import('../pages/Settings/Settings')
);
const AsyncChangePassword = asyncComponent(() =>
  import('../components/ChangePassword/ChangePassword')
);
const AsyncChangeEmail = asyncComponent(() =>
  import('../components/ChangeEmail/ChangeEmail')
);
const AsyncNotFound = asyncComponent(() =>
  import('../pages/NotFound/NotFound')
);
const AsyncForbidden = asyncComponent(() =>
  import('../pages/Forbidden/Forbidden')
);
const AsyncFileSubmission = asyncComponent(() =>
  import('../pages/FileSubmission/FileSubmission')
);
const AsyncDirettoInbox = asyncComponent(() =>
  import('../pages/DirettoInbox/DirettoInbox')
);
const AsyncVipr = asyncComponent(() =>
  import('../pages/Vipr/Vipr')
);
const AsyncOutcomesViewer = asyncComponent(() =>
  import('../pages/OutcomesViewer/OutcomesViewer')
);
const AsyncTOCViewer = asyncComponent(() =>
  import('../pages/TOCViewer/TOCViewer')
);
const AsyncAdmin = asyncComponent(() =>
  import('../pages/Admin/Admin')
);
const AsyncManageAcrs = asyncComponent(() =>
  import('../pages/ManageACRS/ManageAcrs')
);
const AsyncProfile = asyncComponent(() =>
  import('../pages/Profile/Profile')
);
const AsyncUserList = asyncComponent(() =>
  import('../components/UserList/UserList')
);
const AsyncInbox = asyncComponent(() =>
  import('../pages/Inbox/Inbox')
);
const AsyncECMS = asyncComponent(() =>
  import('../pages/ECMS/ECMS')
);
const AsyncConformance = asyncComponent(() =>
  import('../pages/Conformance/Conformance')
);
const AsyncHealthDirectory = asyncComponent(() =>
  import('../pages/HealthDirectory/HealthDirectory')
);
const AsyncSupport = asyncComponent(() =>
  import('../pages/Support/Support')
);
const AsyncAnalytics = asyncComponent(() =>
  import('../pages/Analytics/Analytics')
);
const AsyncOrganizationAdmin = asyncComponent(() =>
  import('../pages/OrganizationAdmin/OrganizationAdmin')
);
const AsyncOidMapperAdmin = asyncComponent(() =>
  import('../pages/OidMapperAdmin/OidMapperAdmin')
);
const AsyncOperationsAdmin = asyncComponent(() =>
  import('../pages/OperationsAdmin/OperationsAdmin')
);
const AsyncSdohReporting = asyncComponent(() =>
  import('../pages/SDOHReporting/SDOHReporting')
);


export default () => {
  const permissions = useSelector((state) => state.permissions);

  const {
    accessTocViewer,
    accessManageAcrs,
    accessFileSub,
    accessEcms,
    accessVipr,
    accessSolutionsCenter,
    accessHd,
    accessConform,
    accessDiretto,
    accessOperations,
    accessSdohReporting
  } = permissions.permissions;

  return (
    <Switch>
      <Route
        exact
        path="/ssoexpiredsession"
        component={SSOExpiredSession}
      />
      <UnauthenticatedRoute
        path="/auth"
        exact
        component={AsyncAuth}
      />
      <UnauthenticatedRoute
        path="/callback"
        exact
        component={AsyncCallback}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={AsyncLogin}
      />
      <UnauthenticatedRoute
        path="/login/reset"
        exact
        component={AsyncResetPassword}
      />
      <UnauthenticatedRoute
        path="/login/expired"
        exact
        component={AsyncExpiredPassword}
      />
      <AdminRoute
        path="/admin/oidmapperadmin"
        exact
        component={AsyncOidMapperAdmin}
      />
      <AdminRoute
        path="/admin/organizationadmin"
        exact
        component={AsyncOrganizationAdmin}
      />
      <AdminRoute
        path="/admin/userlist"
        exact
        component={AsyncUserList}
      />
      <AdminRoute
        path="/admin"
        exact
        component={AsyncAdmin}
      />
      <AdminRoute
        path="/admin/analytics"
        exact
        component={AsyncAnalytics}
      />
      <AuthenticatedRoute
        path="/"
        exact
        component={AsyncHome}
      />
      <AuthenticatedRoute
        path="/settings"
        exact
        component={AsyncSettings}
      />
      <AuthenticatedRoute
        path="/settings/password"
        exact
        component={AsyncChangePassword}
      />
      <AuthenticatedRoute
        path="/settings/email"
        exact
        component={AsyncChangeEmail}
      />
      <AuthenticatedRoute
        path="/manageacrs"
        exact
        component={accessManageAcrs ? AsyncManageAcrs : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/filesubmission"
        exact
        component={accessFileSub ? AsyncFileSubmission : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/healthdirectory"
        exact
        component={accessHd ? AsyncHealthDirectory : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/conformance"
        exact
        component={accessConform ? AsyncConformance : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/settings/profile"
        exact
        component={AsyncProfile}
      />
      <AuthenticatedRoute
        path="/direttoinbox"
        exact
        component={accessDiretto ? AsyncDirettoInbox : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/vipr"
        exact
        component={accessVipr ? AsyncVipr : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/outcomesviewer"
        exact
        component={AsyncOutcomesViewer}
      />
      <AuthenticatedRoute
        path="/tocviewer"
        exact
        component={accessTocViewer ? AsyncTOCViewer : AsyncForbidden}
      />
      {/* inbox == solutions center */}
      <AuthenticatedRoute
        path="/inbox"
        exact
        component={accessSolutionsCenter ? AsyncInbox : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/ecms"
        exact
        component={accessEcms ? AsyncECMS : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/Support"
        exact
        component={AsyncSupport}
      />
      <AuthenticatedRoute
        path="/operationsadmin"
        exact
        component={accessOperations ? AsyncOperationsAdmin : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/sdohreporting"
        exact
        component={accessSdohReporting ? AsyncSdohReporting : AsyncForbidden}
      />
      <AuthenticatedRoute
        path="/forbidden"
        exact
        component={AsyncForbidden}
      />
      <AuthenticatedRoute
        component={AsyncNotFound}
      />
    </Switch>
  );
};
