import React, { useContext, useEffect, useState } from 'react';

import { Button, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Brand from '../Brand/Brand';

import { AuthContext } from '../../context/AuthContext';

import './TopNav.css';

const TopNav = () => {
  const template = process.env.REACT_APP_TEMPLATE;

  const user = useSelector((state) => state.user);
  const { isLoggedIn, handleSignout } = useContext(AuthContext);
  const [openAccountDropdown, setOpenAccountDropdown] = useState(false);
  const [openCareCoordinationDropdown, setOpenCareCoordinationDropdown] = useState(false);
  const [openInboxDropdown, setOpenInboxDropdown] = useState(false);
  const [openAdministrativeDropdown, setOpenAdministrativeDropdown] = useState(false);
  const [navBarHeader, setNavBarHeader] = useState("");

  const permissions = useSelector((state) => state.permissions);

  const { isSuperAdmin, isAdmin } = permissions;

  const {
    accessTocViewer,
    accessManageAcrs,
    accessFileSub,
    accessEcms,
    accessSolutionsCenter,
    accessHd,
    accessConform,
    accessDiretto,
    accessOperations,
    accessSdohReporting
  } = permissions.permissions;

  const showCareCoordination = () => {
    if (
      accessTocViewer ||
      accessFileSub ||
      accessManageAcrs ||
      accessSolutionsCenter
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showAdministrative = () => {
    if (
      accessHd ||
      accessConform ||
      accessSdohReporting
    ) {
      return true;
    } else {
      return false;
    }
  };

  //allows the header text to be set to a custom name based on the template env variable
  useEffect(() => {
    if (template === 'gahin') {
      setNavBarHeader("GeorgiaConnX")
    }
    else if (template === 'njhin') {
      setNavBarHeader("NJHIN");
    }
    else {
      setNavBarHeader("MIGateway");
    }
  }, [template])

  return (
    <div>
      <Navbar
        bg="custom"
        expand="lg"
        variant="dark"
        className="migateway-navbar"
      >
        <Navbar.Brand>
          <NavLink to="/">
            <Brand title={navBarHeader} />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {isLoggedIn ? (
            <Container>
              <Nav className="mr-auto">
                <NavLink exact to="/" className="nav-link nav-link-margin">
                  Home
                </NavLink>
                {showCareCoordination() && (
                  <NavDropdown
                    title="Care Coordination"
                    id="care-coordination-nav-dropdown"
                    className="nav-link-margin"
                    onMouseEnter={() => setOpenCareCoordinationDropdown(true)}
                    onMouseLeave={() =>
                      setOpenCareCoordinationDropdown(false)
                    }
                    show={openCareCoordinationDropdown}
                  >
                    {accessTocViewer && (
                      <NavLink exact to="/tocviewer" className="nav-link">
                        TOC Viewer
                      </NavLink>
                    )}
                    {accessFileSub && (
                      <NavLink
                        exact
                        to="/filesubmission"
                        className="nav-link"
                      >
                        File Submission
                      </NavLink>
                    )}
                    {accessManageAcrs && (
                      <NavLink exact to="/manageacrs" className="nav-link">
                        Manage ACRS
                      </NavLink>
                    )}
                    {accessSolutionsCenter && (
                      <NavLink exact to="/inbox" className="nav-link">
                        Inbox
                      </NavLink>
                    )}
                  </NavDropdown>
                )}
                {accessDiretto && (
                  <NavDropdown
                    title="Inbox"
                    id="inbox-nav-dropdown"
                    className="nav-link-margin"
                    onMouseEnter={() => setOpenInboxDropdown(true)}
                    onMouseLeave={() =>
                      setOpenInboxDropdown(false)
                    }
                    show={openInboxDropdown}
                  >
                    <NavLink
                      exact
                      to="/direttoinbox"
                      className="nav-link"
                    >
                      Diretto Inbox
                    </NavLink>
                    <NavLink
                      exact
                      to="/newinbox"
                      className="nav-link"
                    >
                      New Inbox Placeholder
                    </NavLink>
                  </NavDropdown>
                )}
                {showAdministrative() && (
                  <NavDropdown
                    title="Administrative"
                    id="administrative-nav-dropdown"
                    className="nav-link-margin"
                    onMouseEnter={() => setOpenAdministrativeDropdown(true)}
                    onMouseLeave={() => setOpenAdministrativeDropdown(false)}
                    show={openAdministrativeDropdown}
                  >
                    {accessConform && (
                      <NavLink exact to="/conformance" className="nav-link">
                        Conformance Reporting
                      </NavLink>
                    )}
                    {accessSdohReporting && (
                      <NavLink exact to="/sdohreporting" className="nav-link">
                        SDOH Reporting
                      </NavLink>
                    )}
                    {accessHd && (
                      <NavLink
                        exact
                        to="/healthdirectory"
                        className="nav-link"
                      >
                        Health Directory
                      </NavLink>
                    )}
                  </NavDropdown>
                )}
                {accessEcms && (
                  <NavLink exact to="/ecms" className="nav-link">
                    eCMS
                  </NavLink>
                )}
                <NavLink exact to="/support" className="nav-link">
                  Support
                </NavLink>
              </Nav>
              {user && (
                <div className="mr-sm-2">
                  <span className="float-left nav-link account">
                    <i className="material-icons">account_circle</i>{' '}
                  </span>

                  <NavDropdown
                    title={user.givenName.replace(/['"]+/g, '')}
                    id="user-nav-dropdown"
                    onMouseEnter={() => setOpenAccountDropdown(true)}
                    onMouseLeave={() => setOpenAccountDropdown(false)}
                    show={openAccountDropdown}
                  >
                    <NavLink exact to="/settings" className="nav-link">
                      Settings
                    </NavLink>
                    {isSuperAdmin && (
                      <>
                        <NavLink exact to="/admin" className="nav-link">
                          Admin
                        </NavLink>
                        <NavLink
                          exact
                          to="/admin/organizationadmin"
                          className="nav-link"
                        >
                          Organization Management
                        </NavLink>
                        <NavLink
                          exact
                          to="/admin/oidmapperadmin"
                          className="nav-link"
                        >
                          OID Mapping Management
                        </NavLink>
                      </>
                    )}
                    {accessOperations && (isSuperAdmin || isAdmin) && (
                      <NavLink
                        exact
                        to="/operationsadmin"
                        className="nav-link"
                      >
                        Operations
                      </NavLink>
                    )}
                    <NavDropdown.Divider />
                    <Button
                      className="nav-link"
                      onClick={handleSignout}
                      variant="link"
                    >
                      Logout
                    </Button>
                  </NavDropdown>
                </div>
              )}
            </Container>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TopNav;
