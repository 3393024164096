import React from 'react';

import { Image } from 'react-bootstrap';

import pkg from '../../../package.json';
import env from '../../Environment.json';
import uphieLogo from '../../img/UPHIELogo_white_letters_color_image189x32.png';

import './Brand.css';

const Brand = ({ title }) => {
  const version = pkg.version;
  const url = window.location.href;
  const environment = env.Environment;
  return (
    <div className="Brand">
      <span className="title">
        {url.includes(process.env.REACT_APP_UPHIE_URL) ? (
          <Image src={uphieLogo} alt="UPHIE logo" />
        ) : (
          <>{title}</>
        )}
      </span>{' '}
      {environment !== 'Prod' && (
        <span style={{ color: 'white', marginLeft: '1rem' }}>
          ({environment} {version})
        </span>
      )}
    </div>
  );
};

export default Brand;
